<template>
  <div v-if="loginFailed" class='login-failed'>
    <!-- 获取账户cookie失败 -->
    <span style='color:#ccc'>账号登录失败，请重新登录</span>
    <a href="https://ad.oceanengine.com/pages/login/index.html?name=#/ad" target="_blank">跳转链接</a>
  </div>
  <div v-else style="height: 100%;width:100%" v-loading="loading">
    <iframe
        id="account_iframe"
        name="ifrmname"
        width="100%"
        height="100%"
    ></iframe>
  </div>
</template>

<script>
  export default {
    name: "putAd",
    props: {
      account: Object
    },
    data() {
      return {
        loading: false,
        loginFailed: false
      }
    },
    watch: {
      account(newVal) {
        let _This = this;
        this.$nextTick(() => {
          let dom = document.getElementById('account_iframe');
          if (newVal) {
            try {
              _This.loading = true;
            _This.loginFailed = false;
            chrome.runtime.sendMessage("figgdochegaejmdigdncnidhdpdeaaog", {
            // chrome.runtime.sendMessage("gdpdodfafaiacgmnmchoikalikiemakp", {
              oceanengine: 'login',
              aadvid: newVal.userId
            }, function (response) {
              if (response) {
                response = JSON.parse(response);
                if (response.err === 0 || response.err === 2) {
                  // ! 重置dom.src 为了使其在切换账号后能够刷新页面
                  dom.src = ''
                  // let link = "https://ad.oceanengine.com/pages/promotion.html?aadvid=1663822009350174#/ad";
                  // dom.src = `https://ad.oceanengine.com/pages/promotion.html?aadvid=${newVal.userId}#/ad`;
                  // !如果登陆了改地址会直接重定向
                  setTimeout(()=>{
                    let favicon = document.getElementById('favicon');
                    favicon.setAttribute('href', `/favicon.ico?aadvid=${newVal.userId}`);
                    dom.src='https://ad.oceanengine.com/pages/login/index.html?name=#/ad'
                  },100)
                } else {
                  _This.loginFailed = true;
                  _This.$message.error("账号登录失败,请重新登录账号!")
                  // !账号失败直接重定向到登录页面
                  // dom.src='https://ad.oceanengine.com/pages/login/index.html?name=#/ad'

                }
              } else {
                _This.$message.error("请安装或更新微信浏览器插件")
              }
              _This.loading = false;
              return true
            })
            }catch (e) {
               this.$message.error("请安装微信浏览器插件")
            }
          } else if(newVal === null) {
            _This.loginFailed = false;
            _This.loading = false;
            this.$nextTick(()=>{
              // let dom = document.getElementById('account_iframe');
              // this.loginFailed = true;
              // dom.src = `https://ad.oceanengine.com/pages/login/index.html?name=#/ad`
              // this.$message({
              //   showClose:true,
              //   message:'请点击下面的链接添加账号！',
              //   type:'warning'
              // })
            })
          }else {
             _This.loading = false;
            dom.src = ""
          }
        })
      }
    },
  }
</script>

<style scoped>
a{
    color:#3f80ff;
}
  .empty-cont {
    text-align: center;
    color: #999;
  }
  .login-failed{
    
  }
</style>
