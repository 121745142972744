<template>
  <el-container style="height: 100%">
    <el-aside width="250px" class="side-bar">
      <div class="search-cont">
        <el-button-group style="width: 100%;margin-bottom: 10px">
          <el-button type="primary" @click="init">刷新数据</el-button>
          <el-button type="primary" @click="toEdit(null)">添加账号</el-button>
        </el-button-group>
        <el-select v-model="categoryId" placeholder="选择分类" style="width: 100%" clearable @change="init">
          <el-option
              v-for="item in classifyList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input placeholder="账号名称" v-model="search" class="input-with-select" @keydown.enter.native="init">
          <el-button slot="append" icon="el-icon-search" @click="init"></el-button>
        </el-input>
      </div>
      <ul class="el-menu-demo">
        <li v-for="item in accountList" :key="item.id" class="account-item"
            :class="{active:current && current.userId===item.userId}"
            @click="handleSelect(item)">
          <p class="class-name" :title="item.nickname">{{item.nickname}}</p>
          <div style="display: flex;justify-content:flex-start;align-items:center">
            <user-avatar :info="item" nameProp="nickname" :size="30"/>
            <div class="account-info">
              <p class="class-name" style="padding-left: 10px" :title="item.remark">{{item.remark}}</p>
              <div class="flex-between">
                <p style="padding-left: 10px;font-size: 12px">ID：{{item.userId}}</p>
                             <span class="delete-btn" @click="handleDelete(item)">删除</span>
                <el-dropdown size="mini" @command="(command)=>handleMenu(item,command)" trigger="click">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="remark">修改备注</el-dropdown-item>
                    <el-dropdown-item command="class">修改分类</el-dropdown-item>
                    <el-dropdown-item command="delete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </li>
        <li v-if="accountList.length===0" class="empty-text">暂无数据</li>
      </ul>
    </el-aside>

    <el-main class="main-cont">
      <PutAd :account="current"/>
    </el-main>

    <el-dialog
        title="修改账号分类"
        :visible.sync="isEdit"
        width="450px"
        :before-close="()=>this.isEdit = false">
      <el-form label-width="60px">
        <el-form-item label="分类">
          <el-select v-model="classifyId" placeholder="选择分类" style="width: 100%">
            <el-option
                v-for="item in classifyList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isEdit = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitEdit">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>

</template>

<script>
  import {getAllAccount, deleteAccount, updateAccount} from "@/api/extend";
  import {getClassifyList} from "@/api/classify";
  import UserAvatar from "@/components/UserAvatar"
  import PutAd from "./putAd"
  import {mapActions} from 'vuex'

  export default {
    name: "Extend",
    data() {
      return {
        current: null,
        page: 0,
        accountList: [],
        loading: false,
        search: "",
        classifyList: [],
        categoryId: null,
        isEdit: false,
        classifyId: null
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.init();
        vm.setCollapsed(true)
      })
    },
    beforeRouteLeave(to, from, next) {
      this.setCollapsed(false);
      next()
    },
    mounted() {
      getClassifyList({
        type: 2
      }).then(res => {
        this.classifyList = res;
      })
    },
    methods: {
      handleSubmitEdit() {
        updateAccount(this.current.id, {
          categoryId: this.classifyId
        }).then(() => {
          this.init();
          this.isEdit = false;
          this.$message.success("操作成功");
        })
      },
      handleMenu(data, type) {
        switch (type) {
          case 'delete':
            this.handleDelete(data);
            break;
          case 'remark':
            this.$prompt('请输入备注', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputValue: data.remark ? data.remark : ""
            }).then(({value}) => {
              updateAccount(data.id, {
                remark: value
              }).then(() => {
                this.init();
                this.$message.success("操作成功");
              })
            }).catch(() => {
            });
            break;
          case 'class':
            this.current = data;
            this.classifyId = data ? data.categoryId : null;
            this.isEdit = true;
            break;
        }
      },
      toEdit(item) {
        this.current = item;
        try {
          chrome.runtime.sendMessage("figgdochegaejmdigdncnidhdpdeaaog", {
          // chrome.runtime.sendMessage("gdpdodfafaiacgmnmchoikalikiemakp", {
            oceanengine: 'toAdd',
          },()=>{
             window.open('https://ad.oceanengine.com/pages/login/index.html?name=#/ad',"_blank")
          });
        } catch (e) {
          this.$message.success("请安装微信浏览器插件")
        }
      },
      init() {
        this.loading = true;
        getAllAccount({
          isPage: 0,
          categoryId: this.categoryId,
          search: this.search
        }).then(res => {
          this.accountList = res;
          const account = sessionStorage.getItem('extend_account');
          // this.current = account && res.length !==0 ? JSON.parse(account) : res[0];
          if (account) {
            const obj = JSON.parse(account);
            if (res.includes(item => item.id === obj.id)) {
              this.current = obj;
            } else {
              this.current = res[0]
            }
          } else {
            this.current = res[0]
          }
        }).finally(() => this.loading = false)
      },
      handleSelect(item) {
        sessionStorage.setItem('extend_account', JSON.stringify(item));
        this.current = item;
      },
      handleDelete(item) {
        this.$confirm('此操作将永久删除该巨量引擎账号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteAccount(item.id).then(() => {
            this.$message.success("删除成功");
            this.init();
          })
        }).catch(() => {
        });
      },
      ...mapActions({
        "setCollapsed": "setCollapsed"
      })
    },
    components: {
      UserAvatar,
      PutAd
    }
  }
</script>

<style scoped>
  .account-item {
    /* display: flex; */
    /* justify-content: flex-start;
    align-items: center; */
    padding: 10px;
    border-bottom: 1px solid #ececec;
    cursor: pointer;
  }

  .account-item.active {
    color: #1c9399;
  }

  .account-info {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
  }

  .account-item:hover .delete-btn {
    display: inline-block;
  }

  .search-cont {
    padding: 15px;
    border-bottom: 1px solid #ececec;
  }

  .el-menu-demo {
    border: 0;
    overflow: auto;
    flex: 1;
  }

  .side-bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  .input-with-select {
    width: 100%;
    margin-top: 10px;
  }

  .class-name {
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .main-cont {
    margin: 0 10px;
    background-color: #fff;;
    padding: 10px;
    display:flex;
    justify-content: center;
    align-items:center;
  }

  .empty-text {
    text-align: center;
    padding: 15px;
    color: #999;
  }

  .delete-btn {
    font-size: 12px;
    color: #F56C6C;
    cursor: pointer;
    display: none;
  }
</style>
